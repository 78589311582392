import Player from '@vimeo/player';

export function setupVideos() {

    const vimeoPlayers = document.querySelectorAll('[data-video]');
    Array.from(vimeoPlayers).forEach(video => {
        const player = new Player(video);

        player.on('play', function() {
            if (video.parentNode) {
                video.parentNode.classList.add('video--playing');
            }
        });
    });

    // Play video by elements with attribute 'data-video-play'
    // Define the attribute value with a dom id for the video or leave empty => then sibling iframe will be used
    const vimeoPlayTriggers = document.querySelectorAll('[data-video-play]');
    Array.from(vimeoPlayTriggers).forEach(playTrigger => {
        const video = playTrigger.getAttribute('data-video-play') != '' ? document.getElementById(playTrigger.getAttribute('data-video-play')) : playTrigger.parentNode.querySelector('iframe');
        if (video) {
            const player = new Player(video);

            playTrigger.addEventListener('click', e => {
                e.preventDefault();
                player.play();
            });
        }
    });
}