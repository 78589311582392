import { onWindowResize } from './utils/windowResize';
import { onReady } from './utils/onReady';
import { initVhUnitOverwrite } from './utils/helpers';
import { setHeaderVideoWidth } from './modules/header';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupIntersect } from './components/intersect';
import { setupVideos } from './components/video';
import { setupFilters } from './components/filter';
import { setupAccordions } from './components/accordion';
import { setupSwipers } from './components/swiper';
import { setupNavigation } from './components/navigation';
import { setupProcessLine } from './modules/process-line';

function init() {
    document.body.classList.remove('standby');

    onReady(() => {
        initVhUnitOverwrite(setHeaderVideoWidth);
        setupAnchorLinkScroll();
        setupIntersect();
        setupVideos();
        setupFilters();
        setupAccordions();
        setupSwipers();
        setupNavigation();
        setupProcessLine();
    });

    onWindowResize(() => {
        setHeaderVideoWidth();
    });
}

init();
