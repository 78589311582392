/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

// Used keycodes
const keycode = {
    space: 32,
    arrowUp: 38,
    arrowDown: 40,
    home: 36,
    end: 35
};

/**
 * Default element selector is data-action*="accordion"
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class Accordion {
    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} trigger
     */
    constructor(trigger) {

        this.dom = {
            trigger,
            accordion: trigger.parentNode,
            content: trigger.parentNode.querySelector('.accordion__content')
        };

        this.dom.trigger.addEventListener('click', e => {

            if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
                e.preventDefault();
            }

            if (!this.dom.accordion.classList.contains('js-closed')) {
                closeAccordion(this.dom.accordion, this.dom.content);
            } else {
                openAccordion(this.dom.accordion, this.dom.content);
            }

            // Set the inverted value of aria-expanded
            const ariaExpanded = this.dom.trigger.getAttribute('aria-expanded') || 'false';
            this.dom.trigger.setAttribute('aria-expanded', ariaExpanded === 'false');
        });
    }
}
function openAccordion(accordion, content) {
    content.style.height = `${content.scrollHeight}px`;
    content.addEventListener('transitionend', function (e) {
        if (e.propertyName === 'height') {
            content.style.height = null;
        }
    });
    accordion.classList.remove('js-closed');
    content.setAttribute('aria-hidden', false);
}
function closeAccordion(accordion, content) {
    content.style.height = `${content.scrollHeight}px`;
    setTimeout(() => {
        content.style.height = '0';
    }, 0);
    accordion.classList.add('js-closed');
    content.setAttribute('aria-hidden', true);
}
function openAccordByHashId() {
    if (window.location.hash.indexOf('accordion') > -1) {
        const content = document.getElementById(window.location.hash.replace('#', ''));
        if (content) {
            const accord = document.getElementById(content.getAttribute('aria-labelledby'));
            accord.click();
        }
    }
}

/**
 * Initialise Accordions with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupAccordions(selector = '[data-action="accordion"]') {
    const accordions = document.querySelectorAll(selector);
    if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
            void new Accordion(accordions[i]);
        }
        openAccordByHashId();
    }
}

window.addEventListener('keydown', event => {
    const focusedElement = document.activeElement;
    if (focusedElement) {
        if (event.keyCode === keycode.arrowUp) {
            // Focus previous accordion
            if (focusedElement.closest('.accordion') && focusedElement.closest('.accordion').previousElementSibling && focusedElement.closest('.accordion').previousElementSibling.querySelector('.accordion__head')) {
                focusedElement.closest('.accordion').previousElementSibling.querySelector('.accordion__head').focus();
            }
        }
        if (event.keyCode === keycode.arrowDown) {
            // Focus next accordion
            if (focusedElement.closest('.accordion') && focusedElement.closest('.accordion').nextElementSibling && focusedElement.closest('.accordion').nextElementSibling.querySelector('.accordion__head')) {
                focusedElement.closest('.accordion').nextElementSibling.querySelector('.accordion__head').focus();
            }
        }
        if (event.keyCode === keycode.home) {
            // Focus first accordion
            if (focusedElement.closest('.accordion') && focusedElement.closest('.accordion').parentNode.querySelector('.accordion:first-child .accordion__head')) {
                event.preventDefault();
                focusedElement.closest('.accordion').parentNode.querySelector('.accordion:first-child .accordion__head').focus();
            }
        }
        if (event.keyCode === keycode.end) {
            // Focus last accordion
            if (focusedElement.closest('.accordion') && focusedElement.closest('.accordion').parentNode.querySelector('.accordion:last-child .accordion__head')) {
                event.preventDefault();
                focusedElement.closest('.accordion').parentNode.querySelector('.accordion:last-child .accordion__head').focus();
            }
        }
    }
});