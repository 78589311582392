export function setupNavigation(selector = '.navigation') {
    const navigation = document.querySelector(selector);

    if (navigation) {

        // burgermenu
        const navigationBurgerBtn = navigation.querySelector('.navigation__menu-icon');
        if (navigationBurgerBtn) {
            navigationBurgerBtn.addEventListener('click', () => {
                if (!document.body.classList.contains('js-navigation-open')) {
                    document.body.classList.add('js-navigation-open');
                } else {
                    document.body.classList.remove('js-navigation-open');
                }
            });
        }

        // submenu/languageSelector
        const navigationItemsWithSub = navigation.querySelectorAll('.navigation__item--has-sub');
        const canHover = window.matchMedia('(hover: hover)').matches;

        Array.from(navigationItemsWithSub).forEach(item => {
            const triggerLink = item.querySelector('.navigation__link');
            const subMenu = item.querySelector('.navigation__submenu-wrap');
            const languageSelector = item.querySelector('.navigation__lang-wrap');

            if (subMenu) {
                if (!canHover) {
                    triggerLink.addEventListener('click', e => {
                        e.preventDefault();
                        if (!item.classList.contains('js-closed')) {
                            closeMenu(subMenu, item);
                        } else {
                            openMenu(subMenu, item);
                        }
                    });
                }
            }

            if (languageSelector) {
                if (!canHover) {
                    triggerLink.addEventListener('click', e => {
                        e.preventDefault();
                        if (!item.classList.contains('js-closed')) {
                            closeMenu(languageSelector, item);
                        } else {
                            openMenu(languageSelector, item);
                        }
                    });
                }
            }
        });
    }
}

function openMenu(subMenu, item) {
    subMenu.style.height = `${subMenu.scrollHeight}px`;
    subMenu.addEventListener('transitionend', function (e) {
        if (e.propertyName === 'height') {
            subMenu.style.height = null;
        }
    });
    item.classList.remove('js-closed');
}

function closeMenu(subMenu, item) {
    subMenu.style.height = `${subMenu.scrollHeight}px`;
    setTimeout(() => {
        subMenu.style.height = '0';
    }, 0);
    item.classList.add('js-closed');
}
