export function setHeaderVideoWidth (selector = '.header') {
    const header = document.querySelector(selector);

    if (header) {
        const headerVideo = header.querySelector('.header__video');

        if (headerVideo) {
            const headerWidth = header.clientWidth;
            const headerHeight = header.clientHeight;
            let videoHeight = headerWidth * .5625;

            headerVideo.style.width = null;
            headerVideo.style.height = Math.ceil(videoHeight) + 'px';

            if (headerHeight > videoHeight) {
                let videoWidth = headerHeight * 1.777777777777778;

                headerVideo.style.height = null;
                headerVideo.style.width = Math.ceil(videoWidth) + 'px';
            }
        }
    }
}